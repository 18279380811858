import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'
import 'virtual:svg-icons-register'

import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupA11yPlugin from '@swup/a11y-plugin'
import SwupDebugPlugin from '@swup/debug-plugin'
import SwupProgressPlugin from '@swup/progress-plugin'

import { createPinia } from 'pinia'
import { useUiStore } from '~/store/modules/ui'

import SentryConfig from '~/types/SentryConfig'

import Hydrater from '~/hydrater/Hydrater'
import HydrationStrategy from '~/hydrater/base/HydrationStrategy'
import VueHydratable from '~/hydrater/hydratables/VueHydratable'

import { useApp } from '~/composables/useApp'

import.meta.glob('../img/**/*', { as: 'url', eager: true })

// Config from HTML
const COMPREHENSIBLE_CLASSROOM = window.COMPREHENSIBLE_CLASSROOM || {
  CONFIG: {
    SENTRY: {} as SentryConfig,
    URLS: {} as Record<string, string>,
  },
}

// Globally shared pinia instance for all Vue apps
const pinia = createPinia()
// We need to initialize a Vue instance with Pinia for useUiStore to work in our Swup
// hook below. We never do anything  else with this component, it's just a dummy / kludge
useApp(pinia, {
  mountName: 'StoreManager',
})
const uiStore = useUiStore()


// @see https://swup.js.org/plugins/
const swupPlugins = [
  new SwupProgressPlugin(),
  new SwupHeadPlugin(),
  new SwupA11yPlugin(),
]

if (COMPREHENSIBLE_CLASSROOM.CONFIG?.DEBUG) {
  swupPlugins.push(new SwupDebugPlugin())
}

// Page transition manager
const swup = new Swup({
  containers: ['#main', '#store-config'],
  // animationScope: 'containers',
  plugins: swupPlugins,
})

// Component Hydrater - see src/assets/js/hydrater/README.md for more info
const hydrater = new Hydrater([
  new HydrationStrategy({
    name: 'vue',
    hydratable: VueHydratable,
    factory: (el, { mountName }) => useApp(pinia, {
      mountName: `Hydrated${mountName ||'App'}`,
      ...COMPREHENSIBLE_CLASSROOM,
      FLAGS: {
        withSentry: true,
      },
    }),
  }),
])

// Don't mount components if we have Craft / Larapack debug dump output in the page
const shouldMountComponents = !import.meta.env.DEV || typeof window?.Sfdump !== 'function'

if (shouldMountComponents) {
  document.querySelector('html').classList.add('js-loaded')
  // Mount components on page load
  window.addEventListener('DOMContentLoaded', () => {
    hydrater.update()
    window.Formie?.initForms()
  })

  // Clean up/reset Vue state when we start a transition
  swup.hooks.on('animation:out:start', () => {
    uiStore.startPageTransition()
    uiStore.resetMobileNav()
    uiStore.toggleNavDropdown()
  })
  swup.hooks.on('animation:in:start', () => {
    uiStore.endPageTransition()
  })
  // When we finish a transition, Create and mount new components / unmount old ones
  swup.hooks.on('page:view', (visit) => {
    hydrater.update()
    uiStore.setRoute()
    window.Formie?.initForms()
  })
}
// Expose our global state to the window object to aid debugging
window.COMPREHENSIBLE_CLASSROOM = {
  ...COMPREHENSIBLE_CLASSROOM,
  swup,
  hydrater,
  pinia,
}
